import React from 'react'
import './css/App.css'

function App() {
  return (
    <div className="App">
      <h1>Audio Drop Player</h1>
    </div>
  )
}

export default App
